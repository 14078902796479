var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(1, "Add new document"),
        "header-class": "header-class-modal-doc-package",
        "body-class": "modal-layout",
        size: "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-footer": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.cancel },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container-modal" },
        [
          _c("div", { staticClass: "form" }, [
            _c(
              "div",
              { staticClass: "card card-border-blue-light" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(4, "Name") } },
                          [
                            _c("b-form-input", {
                              class: {
                                "is-invalid":
                                  _vm.isSubmitted &&
                                  _vm.$v.document.name.$error,
                              },
                              attrs: {
                                type: "text",
                                placeholder: _vm.FormMSG(
                                  6,
                                  "Enter document name ..."
                                ),
                              },
                              model: {
                                value: _vm.document.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.document, "name", $$v)
                                },
                                expression: "document.name",
                              },
                            }),
                            _vm.isSubmitted && !_vm.$v.document.name.required
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          11,
                                          "Please, enter a document name"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(5, "Type") } },
                          [
                            _c("b-form-select", {
                              attrs: { options: _vm.types },
                              model: {
                                value: _vm.document.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.document, "type", $$v)
                                },
                                expression: "document.type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "10" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(7, "File") } },
                          [
                            _c("b-form-file", {
                              ref: "input-file-doc",
                              class: {
                                "is-invalid":
                                  _vm.isSubmitted &&
                                  _vm.$v.document.fileName.$error,
                              },
                              attrs: {
                                accept:
                                  ".gif,.jpg,.jpeg,.png,.pdf,.xlsx,.xls,.doc,.docx,.txt",
                                placeholder: _vm.FormMSG(
                                  8,
                                  "Choose a file or drop here ..."
                                ),
                                "browse-text": _vm.FormMSG(9, "Browse"),
                                "drop-placeholder": _vm.FormMSG(
                                  10,
                                  "Drop file here ..."
                                ),
                              },
                              on: { change: _vm.uploadFile },
                            }),
                            _vm.isSubmitted &&
                            !_vm.$v.document.fileName.required
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(12, "Please, choose a file")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.document.fileName && _vm.document.fileName.length > 0
                      ? _c(
                          "b-col",
                          {
                            staticClass: "d-flex align-items-center",
                            attrs: { cols: "2" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex justify-content-center",
                                staticStyle: {
                                  "margin-top": "16px",
                                  "line-height": "32px",
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-transparent text-color-burning-tomato",
                                    on: { click: _vm.handleTrashFile },
                                  },
                                  [
                                    _c("trash-2", {
                                      attrs: { size: 22, "stroke-width": 1.5 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-row",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-col",
                {
                  staticClass: "pb-2",
                  attrs: { sm: "12", md: "12", lg: "4", xl: "4" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary", block: "" },
                      on: { click: _vm.cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(3, "Cancel")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", block: "" },
                      on: { click: _vm.submit },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Add document")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }