<template>
	<b-modal
		:title="FormMSG(1, 'Add new document')"
		v-model="isOpen"
		header-class="header-class-modal-doc-package"
		body-class="modal-layout"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		hide-footer
		@hidden="cancel"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="container-modal">
			<div class="form">
				<div class="card card-border-blue-light">
					<b-row>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group :label="FormMSG(4, 'Name')">
								<b-form-input
									v-model="document.name"
									type="text"
									:placeholder="FormMSG(6, 'Enter document name ...')"
									:class="{ 'is-invalid': isSubmitted && $v.document.name.$error }"
								/>
								<div v-if="isSubmitted && !$v.document.name.required" class="invalid-feedback">
									{{ FormMSG(11, 'Please, enter a document name') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group :label="FormMSG(5, 'Type')">
								<b-form-select v-model="document.type" :options="types" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="10">
							<b-form-group :label="FormMSG(7, 'File')">
								<b-form-file
									ref="input-file-doc"
									accept=".gif,.jpg,.jpeg,.png,.pdf,.xlsx,.xls,.doc,.docx,.txt"
									:placeholder="FormMSG(8, 'Choose a file or drop here ...')"
									:browse-text="FormMSG(9, 'Browse')"
									:drop-placeholder="FormMSG(10, 'Drop file here ...')"
									@change="uploadFile"
									:class="{ 'is-invalid': isSubmitted && $v.document.fileName.$error }"
								/>
								<div v-if="isSubmitted && !$v.document.fileName.required" class="invalid-feedback">
									{{ FormMSG(12, 'Please, choose a file') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col v-if="document.fileName && document.fileName.length > 0" cols="2" class="d-flex align-items-center">
							<div class="d-flex justify-content-center" style="margin-top: 16px; line-height: 32px">
								<button class="btn-transparent text-color-burning-tomato" @click="handleTrashFile">
									<trash-2 :size="22" :stroke-width="1.5" />
								</button>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>
			<b-row class="d-flex justify-content-end">
				<b-col sm="12" md="12" lg="4" xl="4" class="pb-2">
					<b-button variant="outline-primary" block @click="cancel">
						{{ FormMSG(3, 'Cancel') }}
					</b-button>
				</b-col>
				<b-col sm="12" md="12" lg="4" xl="4">
					<b-button variant="primary" block @click="submit">
						{{ FormMSG(2, 'Add document') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { Eye, Trash2 } from 'lucide-vue';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { store } from '@/store';
import { newDocument } from '@/modules/document-package/cruds/document.crud';
import { getFileExtension } from '@/shared/helpers';

const axios = require('axios').create();

export default {
	name: 'AddDocumentModal',
	mixins: [languageMessages, validationMixin],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		parentId: {
			type: [String, Number],
			required: false,
			default: '0'
		}
	},
	components: {
		Eye,
		Trash2
	},
	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(value) {
				return value;
			}
		},
		types() {
			return this.FormMenu(1011);
		},
		me() {
			return store.state.myProfile.id;
		}
	},
	data() {
		return {
			document: {
				name: '',
				type: 7,
				fileName: '',
				xid: ''
			},
			file: null,
			isSubmitted: false
		};
	},
	methods: {
		cancel() {
			this.isSubmitted = false;
			this.document = {
				name: '',
				type: 7,
				fileName: '',
				xid: ''
			};

			this.file = null;
			this.$emit('add-document-modal:hide', true);
		},
		uploadFile(e) {
			if (e.type === 'drop') {
				this.file = e.dataTransfer.files[0];
			} else {
				this.file = e.target.files[0];
			}
			this.document.fileName = this.file.name;
		},
		handleTrashFile() {
			this.file = null;
			this.document.fileName = '';
			this.$refs['input-file-doc'].reset();
		},
		submit(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			let formData = new FormData();
			formData.append('uploadimage', this.file);
			formData.append('fileName', this.file.name);
			formData.append('fileType', this.file.type);

			const urlStr = process.env.VUE_APP_GQL + '/upload';

			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(async (result) => {
					await newDocument(
						{
							name: this.document.name,
							type: this.document.type,
							fileName: this.document.fileName,
							xid: result.data,
							status: 0, // add new status (sent = 5)
							userId: parseInt(this.me, 10)
						},
						parseInt(this.parentId, 10)
					).then((record) => {
						if (record) {
							record = {
								...record,
								src: `${process.env.VUE_APP_GQL}/images/${record.xid}`,
								thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + record.xid,
								ext: getFileExtension(record.fileName),
								isHovered: false
							};
							this.isSubmitted = false;
							this.$emit('add-document-modal:success', record);
						}
					});

					this.$refs['input-file-doc'].reset();
					this.initData();
				})
				.catch((e) => {
					console.log({ e });
				});
		},
		initData() {
			this.document = {
				name: '',
				type: 7,
				fileName: '',
				xid: ''
			};

			this.file = null;
		}
	},
	validations: {
		document: {
			name: {
				required,
				min: minLength(3)
			},
			fileName: {
				required
			}
		}
	}
};
</script>
